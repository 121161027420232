import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Carousel from '../components/Carousel'
import ShopLink from '../components/ShopLink'
import Div100vh from 'react-div-100vh'

const SimpleIndexPage = () => (
  <Div100vh style={{ overflow: 'hidden' }}>
    <Layout mode="simple">
      <div
        className="columns is-gapless simple__wrapper container"
        style={{ margin: 'auto' }}
      >
        <div className="column">
          <Carousel />
        </div>
        <div className="simple__content column">
          <Link to="/about?from=top">
            <h1 className="byline">Gosuke Kambayashi / PLAT</h1>
          </Link>
        </div>
      </div>
    </Layout>
  </Div100vh>
)

export default SimpleIndexPage
